import { Sector } from 'types';
import { api } from 'utilities/api.axios';

const BASE_URL = 'api/sectors';

export const getSectors = async (): Promise<Sector[]> => api.get(BASE_URL);

export const createSector = async (sector: {
  workspaceId: string;
  name: string;
}): Promise<Sector> => api.post(BASE_URL, sector);

export const updateSector = async (
  id: number,
  sector: {
    workspaceId: string;
    name: string;
  }
): Promise<Sector> => api.put(`${BASE_URL}/${id}`, sector);

export const deleteSector = async (id: number): Promise<Sector> =>
  api.delete(`${BASE_URL}/${id}`);
