import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import React from 'react';
import { EmbedInfo } from 'types';
import styles from './PowerBiViewer.module.scss';

export type PowerBiViewerProps = {
  embedInfo?: EmbedInfo;
};

export const PowerBiViewer = ({ embedInfo }: PowerBiViewerProps) => {
  if (!embedInfo) {
    return null;
  }
  return (
    <PowerBIEmbed
      embedConfig={{
        type: embedInfo?.type.toLowerCase() || 'report', // Supported types: report, dashboard, tile, visual and qna
        embedUrl: embedInfo?.reports[0]?.embedUrl,
        accessToken: embedInfo?.embedToken.token,
        tokenType: models.TokenType.Embed,
        id: embedInfo?.reports[0]?.id,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: true,
            },
            pageNavigation: {
              visible: true,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      }}
      eventHandlers={
        new Map([
          [
            'loaded',
            function () {
              console.log('Report loaded');
            },
          ],
          [
            'rendered',
            function () {
              console.log('Report rendered');
            },
          ],
          [
            'error',
            function (event) {
              console.log(event?.detail);
            },
          ],
        ])
      }
      cssClassName={styles.report}
    />
  );
};
