import { Card } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
import { Report } from 'types';

type ReportCardProps = {
  report: Report;
};

export const ReportCard = ({ report }: ReportCardProps) => {
  return (
    <Link to={report.id}>
      <Card hoverable={true} bordered={false}>
        <Title level={4}>{report.name}</Title>
      </Card>
    </Link>
  );
};
