import { atom } from 'jotai';
import { AppTheme } from 'types/sector';

const appTheme = atom<AppTheme>({ title: '' });

const loading = atom<boolean>(true);

export const ui = {
  appTheme,
  loading,
};
