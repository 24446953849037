import { FC } from 'react';
import { Navigate } from 'react-router';

type ProtectedRouteProps = {
  isAuthenticated: boolean | (() => boolean);
  redirectPath: string;
  element: React.ReactElement;
};

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  element,
  redirectPath,
  isAuthenticated,
}: ProtectedRouteProps) => {
  const auth =
    typeof isAuthenticated === 'function' ? isAuthenticated() : isAuthenticated;

  return auth ? element : <Navigate to={redirectPath} replace />;
};
