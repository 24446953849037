import { AppTheme, ThemeColors } from 'types/sector';

const defaultBanner = '/assets/banners/glastuinbouw.webp';
const defaultColors: ThemeColors = {
  primaryColor: '#f2951e',
  infoColor: '#AECFE9',
  successColor: '#44bf40',
  errorColor: '#ff4d4f',
};

export const extendWithDefaults = (theme: AppTheme): AppTheme => {
  if (!theme.banner) {
    theme.banner = defaultBanner;
  }
  if (!theme.colors) {
    theme.colors = defaultColors;
  }
  return theme;
};
