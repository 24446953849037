import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getReportsEmbedInformation } from 'adapters/reports-adapter';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { PowerBiViewer } from 'components';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { EmbedInfo } from 'types';
import { showNotification } from 'utilities/showNotification';
import styles from './Report.module.scss';

export const Report = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [embedInfo, setEmbedInfo] = useState<EmbedInfo>();
  const navigate = useNavigate();
  const backToOverview = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);
    getReportsEmbedInformation([id])
      .then(setEmbedInfo)
      .catch(e => showNotification('error', e.message))
      .finally(() => setLoading(false));
  }, [id]);

  if (!id) {
    return <Result title="No report selected" />;
  }

  return (
    <>
      <Row className={styles.menu}>
        <Col>
          <Button
            type="default"
            shape="round"
            onClick={backToOverview}
            icon={<FontAwesomeIcon icon="arrow-left" />}>
            Overzicht
          </Button>
        </Col>
      </Row>
      <Row className={styles.reportRow}>
        <Col flex={1}>
          <Skeleton
            loading={loading}
            children={<PowerBiViewer embedInfo={embedInfo} />}
          />
        </Col>
      </Row>
    </>
  );
};
