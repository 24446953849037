import { Spin } from 'antd';
import { atoms } from 'atoms';
import { useAtomValue } from 'jotai/utils';
import { useLocation, useNavigate } from 'react-router-dom';

export const Login = () => {
  const keycloak = useAtomValue(atoms.auth.keycloakInstance);
  const location = useLocation();

  // @ts-ignore
  const redirectPath = location.state.from?.pathname || '/';
  const redirectUri =
    window.location.href.replace(window.location.pathname, '') + redirectPath;

  const navigate = useNavigate();

  if (keycloak.authenticated) {
    navigate(redirectPath);
    return null;
  } else {
    keycloak.login({ redirectUri });
    return <Spin />;
  }
};
