import { Col, Result, Row } from 'antd';
import { ReportCard } from 'components';
import { FC } from 'react';
import { Report } from 'types';

type ReportsOverviewProps = {
  reports?: Report[];
};

export const ReportsOverview: FC<ReportsOverviewProps> = ({ reports }) => {
  if (!reports || !reports.length) {
    return <Result title="No reports" />;
  }

  return (
    <Row gutter={16} style={{ margin: '16px' }}>
      {reports.map((report: Report) => (
        <Col span={6} key={report.id}>
          <ReportCard report={report} />
        </Col>
      ))}
    </Row>
  );
};
