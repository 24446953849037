import { getReports } from 'adapters/reports-adapter';
import { Skeleton } from 'antd';
import { ReportsOverview } from 'components/reports-overview/ReportsOverview';
import { useEffect, useState } from 'react';
import { Report } from 'types';
import { showNotification } from 'utilities/showNotification';

export const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState<Report[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getReports()
      .then(setReports)
      .catch(e => showNotification('error', e.message))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Skeleton
      loading={isLoading}
      children={<ReportsOverview reports={reports} />}
    />
  );
};
