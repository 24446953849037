import { getTheme } from 'adapters/theme-adapter';
import { ConfigProvider, Skeleton } from 'antd';
import { auth } from 'atoms/auth';
import { ui } from 'atoms/ui';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { FC, useEffect, useState } from 'react';
import { extendWithDefaults } from 'utilities/theme';

export const ThemeWrapper: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const setAppTheme = useUpdateAtom(ui.appTheme);
  const keycloakTokenInfo = useAtomValue(auth.keycloakTokenInfo);

  const fetchTheme = async (sector: string) => {
    setIsLoading(true);
    try {
      const theme = await getTheme(sector);
      const extended = extendWithDefaults(theme);
      setAppTheme(extended);
      ConfigProvider.config({
        theme: theme.colors,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTheme(keycloakTokenInfo.SECTOR);
  }, [keycloakTokenInfo]);

  return <Skeleton loading={isLoading}>{children}</Skeleton>;
};
