import axios, { AxiosRequestConfig } from 'axios';

// @ts-ignore
const API_URL = window.API_URL;

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  timeout: 10000,
};

export const api = axios.create(axiosConfig);

api.interceptors.response.use(
  response => response.data,
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
