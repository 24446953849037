import { auth } from 'atoms/auth';
import { ProtectedRoute } from 'components/protected-route';
import { useAtomValue } from 'jotai/utils';
import { Admin, Home, Login, Report } from 'pages';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const AppRouter = (): React.ReactElement => {
  const userRoles = useAtomValue(auth.userRoles);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<Report />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/admin"
        element={
          <ProtectedRoute
            isAuthenticated={userRoles.isApiAdmin}
            redirectPath="/"
            element={<Admin />}
          />
        }
      />
    </Routes>
  );
};
