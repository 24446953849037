import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ConfigProvider } from 'antd';
import nlNL from 'antd/es/locale/nl_NL';
import { AppHeader, ErrorBoundary } from 'components';
import { AuthComponent } from 'components/authentication/authComponent';
import { Provider } from 'jotai';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter } from './AppRouter';
import { ThemeWrapper } from './ThemeWrapper';
library.add(far, fas);

export const App = () => (
  <Suspense fallback={<p>loading</p>}>
    <Provider>
      <AuthComponent>
        <ThemeWrapper>
          <BrowserRouter>
            <ConfigProvider locale={nlNL}>
              <ErrorBoundary>
                <AppHeader />
                <AppRouter />
              </ErrorBoundary>
            </ConfigProvider>
          </BrowserRouter>
        </ThemeWrapper>
      </AuthComponent>
    </Provider>
  </Suspense>
);
