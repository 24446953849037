import { atom } from 'jotai';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import { KeycloakTokenInfo, UserRoles } from 'types';

const keycloak = Keycloak({
  clientId: window.AUTH_CLIENT_ID,
  realm: window.AUTH_REALM,
  url: window.AUTH_URL,
});

const keycloakInstance = atom<KeycloakInstance>(keycloak);

const keycloakTokenInfo = atom<KeycloakTokenInfo>(get => {
  const atomInstance = get(keycloakInstance);
  return (atomInstance.idTokenParsed as KeycloakTokenInfo) ?? {};
});

const userRoles = atom<UserRoles>(get => {
  const tokenInfo = get(keycloakTokenInfo);

  return {
    roles: tokenInfo.roles,
    isApiAdmin: tokenInfo.roles.some(x => x === 'api-admin'),
  };
});

export const auth = {
  keycloakInstance,
  keycloakTokenInfo,
  userRoles,
};
