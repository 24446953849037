import qs from 'qs';
import { EmbedInfo, Report } from 'types';
import { api } from 'utilities/api.axios';
const BASE_URL = 'api/reports';

export const getReportsEmbedInformation = async (
  reportIds: Array<string>
): Promise<EmbedInfo> =>
  api.get(`${BASE_URL}/embedinfo`, {
    params: { ReportIds: reportIds },
    paramsSerializer: params => {
      return qs.stringify(params);
    },
  });

export const getReports = async (): Promise<Report[]> => api.get(`${BASE_URL}`);
