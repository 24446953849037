import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Image, Menu, Row } from 'antd';
import { auth } from 'atoms/auth';
import { ui } from 'atoms/ui';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { Link } from 'react-router-dom';
import styles from './header.module.scss';

export const AppHeader = () => {
  const [keycloak] = useAtom(auth.keycloakInstance);
  const userRoles = useAtomValue(auth.userRoles);
  const appTheme = useAtomValue(ui.appTheme);

  const signOut = () => keycloak.logout();

  return (
    <>
      <Row gutter={16} className={styles.headerMenu}>
        <Col>
          <Image height={48} src="/assets/flynth-logo.png" preview={false} />
        </Col>
        <Col flex={1}>
          <Menu
            mode="horizontal"
            selectable={false}
            selectedKeys={location.pathname.split('/')}
            defaultSelectedKeys={[location.pathname]}
            disabledOverflow={true}>
            <Menu.Item key="home">
              <Link to="/" />
              Home
            </Menu.Item>

            {userRoles.isApiAdmin && (
              <Menu.Item key="admin">
                <Link to="/admin" />
                Admin
              </Menu.Item>
            )}
          </Menu>
        </Col>
        <Col span={1}>
          <Button type="primary" shape="circle" onClick={signOut}>
            <FontAwesomeIcon icon="sign-out-alt" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <img className={styles.bannerImage} src={appTheme.banner} />
        </Col>
      </Row>
    </>
  );
};
