import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  createSector,
  deleteSector,
  getSectors,
  updateSector,
} from 'adapters/sector-adapter';
import { Button, Col, Popconfirm, Row, Space, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { ModifySectorModal } from 'components/modify-sector-modal';
import { useEffect, useState } from 'react';
import { AppTheme, Sector } from 'types';
import { showNotification } from 'utilities/showNotification';
import styles from './Admin.module.scss';

interface ModalState {
  visible: boolean;
  loading?: boolean;
  sector?: Sector;
}

const INIT_STATE = {
  visible: false,
  loading: false,
};

export const Admin = () => {
  const [modalState, setModalState] = useState<ModalState>(INIT_STATE);
  const [isLoading, setIsLoading] = useState(true);
  const [sectors, setSectors] = useState<Sector[]>([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const sectors = await getSectors();
      setSectors(sectors);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const editSector = (sector: Sector) => {
    setModalState({
      sector: sector,
      visible: true,
    });
  };

  const showModal = () => {
    setModalState({ visible: true });
  };

  const modalOnClose = () => {
    setModalState(INIT_STATE);
  };

  const modalOnFinish = async (sector: Sector) => {
    try {
      if (sector.id) {
        await updateSector(sector.id, sector);
      } else {
        await createSector(sector);
      }
      setModalState(INIT_STATE);
      await fetchData();
    } catch (e) {
      console.log(e);
      showNotification('error', 'Kan wijziging niet opslaan');
    }
  };

  const submitDelete = async (id: number) => {
    await deleteSector(id);
    await fetchData();
  };

  const tableColumns: ColumnType<Sector>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'WorkspaceId',
      dataIndex: 'workspaceId',
    },
    {
      title: 'Created',
      dataIndex: 'created',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
    },
    {
      title: 'Banner',
      dataIndex: 'theme',
      render: (value: AppTheme) => {
        return value?.banner;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: number, record: Sector) => {
        return (
          <Space direction="horizontal">
            <Button
              type="default"
              shape="circle"
              onClick={() => editSector(record)}>
              <FontAwesomeIcon icon="edit" />
            </Button>

            <Popconfirm
              title="Weet je het zeker?"
              onConfirm={() => submitDelete(id)}>
              <Button type="default" danger shape="circle">
                <FontAwesomeIcon icon="trash" />
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="end" align="bottom" className={styles.menu}>
        <Col>
          <Button type="primary" shape="round" onClick={showModal}>
            Add sector
          </Button>
        </Col>
      </Row>
      <Table columns={tableColumns} loading={isLoading} dataSource={sectors} />
      <ModifySectorModal
        {...modalState}
        onClose={modalOnClose}
        onFinish={modalOnFinish}
      />
    </>
  );
};
