import { Button, Form, Input, Modal, Space } from 'antd';
import { FC } from 'react';
import { Sector } from 'types';

interface ModifySectorModalProps {
  sector?: Sector;
  visible: boolean;
  loading?: boolean;

  onFinish: (sector: Sector) => void;
  onClose: () => void;
}

export const ModifySectorModal: FC<ModifySectorModalProps> = ({
  visible,
  sector,
  onFinish,
  onClose,
  loading,
}) => {
  const [form] = Form.useForm();

  if (sector) {
    form.setFieldsValue(sector);
  }

  const onCloseClick = () => onClose();

  return (
    <Modal
      visible={visible}
      footer={false}
      destroyOnClose
      onCancel={onCloseClick}
      title={sector ? 'Sector wijzigen' : 'Sector toevoegen'}>
      <Form
        preserve={false}
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        labelAlign="left"
        colon={false}
        form={form}
        onFinish={onFinish}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Form.Item
          name="workspaceId"
          label="WorkspaceId"
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>
        <Form.Item name={['theme', 'title']} label="Title">
          <Input />
        </Form.Item>
        <Form.Item name={['theme', 'banner']} label="Banner">
          <Input />
        </Form.Item>
        <Space size={5} style={{ marginTop: 16 }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            Opslaan
          </Button>
          <Button type="link" onClick={onCloseClick}>
            Annuleren
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};
